const siteConf = {
    domain: 'www.collegerentals.com',
};

require('./modules/menu.desktop');
require('./modules/localStorage');

function ShowPopup(elem_id) {
    import('./popups/showPopup').then(module => {
        module.default(elem_id);
    });
}

function showPreloader() {
    import('./popups/showPreloader').then(module => {
        module.default();
    });
}

function Trigger_Error(elm, txt, scroll) {
    import('./modules/validation/triggerError').then(module => {
        module.default(elm, txt, scroll);
    });
}

function SetErrorText(elm, txt, scroll) {
    import('./modules/validation/setErrorText').then(module => {
        module.default(elm, txt, scroll);
    });
}

function BlinkElem(elm, count, timeDelay) {
    import('./modules/validation/blink').then(module => {
        module.default(elm, count, timeDelay);
    });
}


function dhtmlCampusAveLoadScript(url) {
    import('./helpers/dhtmlCampusAveLoadScript').then(module => {
        module.default(url);
    });
}

function hasHTMLTags(s) {
    import('./helpers/hasHTMLTags').then(module => {
        module.default(s);
    });
}

function FormatPhone(e, input) {
    import('./helpers/formatPhone').then(module => {
        module.default(e, input);
    });
}

function ReplyByEmail(elem, show_ntp) {
    import('./actions/replyByEmail').then(module => {
        if (typeof grecaptcha !== 'undefined') {
            grecaptcha.ready(function () {
                grecaptcha.execute(window.GOOGLE_RECAPTCHA_SITE_KEY, {action: 'submit'}).then(function (token) {
                    module.default(elem, show_ntp, token);
                });
            });
        } else {
            module.default(elem, show_ntp);
        }
    });
    return false;
}

function OpenCheckAbility(elem, id, categ, di, dt, callback) {
    if(!document.querySelector('.g-capthca-script') && window.GOOGLE_RECAPTCHA_SITE_KEY){
        var scriptElement = document.createElement('script');
        scriptElement.className = 'g-capthca-script';
        scriptElement.src = 'https://www.google.com/recaptcha/api.js?render=' + window.GOOGLE_RECAPTCHA_SITE_KEY;
        document.head.appendChild(scriptElement);
    }

    import('./popups/showReplyPopup').then(module => {
        module.default(elem, id, categ, di, dt, callback);
    });
    return false;
}

function openPropertySlide() {
    import('./popups/showPropertySlide').then(module => {
        module.default();
    });
    return false;
}

function closePropertyWindow() {
    import('./actions/closePropertyWindow').then(module => {
        module.default();
    });
}

function showLoginDialog(e, option) {
    import('./popups/showLoginPopup').then(module => {
        module.default(e, option);
    });
    return false;
}

function openUnitFloorplan(src) {
    import('./popups/showFloorplanPopup').then(module => {
        module.default(src);
    });
}

function ForgotPasswordForm() {
    import('./popups/showForgotPasswordPopup').then(module => {
        module.default();
    });
}

function showAlertPopup(topsubText) {
    import('./popups/showAlertPopup').then(module => {
        module.default(topsubText);
    });
}

function submitForgot() {
    import('./actions/forgotPassword').then(module => {
        module.default();
    });
    return false
}

function Ajax_Login(e, option) {
    import('./actions/login').then(module => {
        module.default(e, option);
    });
    return false;
}

function loginRegisterCallback(...args) {
    import('./actions/loginCallback').then(module => {
        module.default(...args);
    });
}

function Logout(e, option) {
    import('./actions/logout').then(module => {
        module.default(e, option);
    });
    return false;
}

function LoadFavorites(page) {
    import('./actions/favorites/loadFavorites').then(module => {
        module.default(page);
    });
}

function checkFavorite(page) {
    import('./actions/favorites/checkFavorite').then(module => {
        module.default(page);
    });
}

function listSlideError(elem) {
    import('./helpers/listSlideImageError').then(module => {
        module.default(elem);
    });
}
window.listSlideError = listSlideError;

function Track(domainID, domainType, subcategoryID, url, pgnm) {
    import('./actions/track').then(module => {
        module.default(domainID, domainType, subcategoryID, url, pgnm);
    });
}

function sendGoogleAnalytics(type, category, action, label) {
    gtag('event', category, {
        'event_action': action,
        'event_label': label
    });
}

jQ(function () {
    checkFavorite();

    if(jQ('input.location_input').length){
        import('./modules/locationSearch').then(module => {
            module.default();
        });
    }

    jQ('*[options="login-required"], .log_in').click(function (e) {
        showLoginDialog(e);
        return false;
    });

    jQ('body').on('keydown', '.phone', function (e) {
        FormatPhone(e, this)
    })

    jQ('body').on('click', '.hotlist', function (e) {
        import('./actions/favorites/hotlistClick').then(module => {
            module.default(e);
        });
    });

    jQ('.menu-hamburger').on('click', function () {
        import('./modules/menu').then(() => {
            if (typeof UloopMenu !== 'undefined') {
                UloopMenu.openMobileMenu();
            }
        });
    });
});
