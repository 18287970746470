jQuery(document).ready(function () {
    jQuery('#desktop-menu-btn, #desktop-menu-block').hover(
        function () {
            jQuery('#desktop-menu-block>.menu-simple-block').show();
            jQuery('#header').css({'z-index' : '31'});
        },
        function () {
            jQuery('#desktop-menu-block>.menu-simple-block').hide();
            jQuery('#header').css({'z-index' : ''});
        }
    );
    jQuery('#desktop-menu-block .menu-row').hover(
        function () {
            jQuery(this).find('.menu-simple-block').first().show();
        },
        function () {
            jQuery(this).find('.menu-simple-block').first().hide();
        }
    );
});
